import React, { lazy, Suspense } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import Loader from 'components/atoms/Loaders/Linear'
import { AdminValidator, NonAuthenticationValidator, RouteValidator } from 'HOC/RouteValidator'
import NotificationSnackbar from 'components/molecules/Notification'
import HomePage from '../pages/home'

const RouterWrapper = (component: React.ElementType) => <RouteValidator component={ component }/>

const Root = () => {
  return (
    <>
      <Router>
        <Suspense fallback={ <Loader/> }>
          <Switch>
            <Route
              exact
              path='/login'
              component={ () => <NonAuthenticationValidator component={ lazy(() => import('pages/Login')) }/> }
            />
            <Route
              exact
              path='/login/password_reset'
              component={ () => <NonAuthenticationValidator
                component={ lazy(() => import('pages/ResetPassword')) }/> }
            />
            <Route
              exact
              path='/login/activate'
              component={ () => <NonAuthenticationValidator component={ lazy(() => import('pages/ActivateAccount')) }/> }
            />
            {/*<Route*/}
            {/*  exact*/}
            {/*  path='/register'*/}
            {/*  component={ () => <NonAuthenticationValidator component={ lazy(() => import('pages/Register')) }/> }*/}
            {/*/>*/}
            <Route
              exact
              path='/caption-editor/:documentId/:sessionId'
              component={ () => RouterWrapper(lazy(() => import('pages/caption-editor'))) }
            />
            <Route
              exact
              path='/transcript-editor/:documentId/:sessionId'
              component={ () => RouterWrapper(lazy(() => import('pages/transcript-editor'))) }
            />
            <Route exact path='/about' component={ () => RouterWrapper(HomePage) } />
            <Route
              exact
              path='/upload'
              component={ () => RouterWrapper(lazy(() => import('pages/home/pages/advanced-upload'))) }
            />
            <Route
              exact
              path='/upload-old'
              component={ () => RouterWrapper(lazy(() => import('pages/home/pages/advanced-upload-old'))) }
            />
            <Route exact path='/unauthorized' component={ lazy(() => import('components/compounds/Unauthorized')) } />
            <Route path='/settings' component={ () => RouterWrapper(lazy(() => import('pages/Settings'))) } />
            <Route
              path='/backoffice'
              component={ () => <AdminValidator component={ lazy(() => import('pages/Backoffice')) } /> }
            />
            <Route path='/custom-models' component={ () => RouterWrapper(lazy(() => import('pages/custom-models'))) } />
            <Route exact path={ [ '/' ] } component={ () => RouterWrapper(lazy(() => import('pages/home'))) } />
            <Route component={ () => RouterWrapper(lazy(() => import('pages/NotFound'))) } />
          </Switch>
        </Suspense>
      </Router>
      <NotificationSnackbar />
    </>
  )
}

export default Root
